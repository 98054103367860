.document-show-wrapper {
  height: 100%;

  ion-card {
    background: var(--ion-color-light);
    border: 1px solid #dddbda;
    border-radius: 10px;
    box-shadow: none;
    margin: 0px;

    .card-title {
      font-weight: $bold;
      margin: 0px;
    }

    ion-list {
      background: white;
      padding-top: 0px;
    }

    ion-item {
      --background: white;
      --padding-start: 0px;
      --inner-padding-end: 0px;
    }
  }
}

.block-card-row {
  display: flex;
  justify-content: space-between;

  align-items: center;
}

.status-badge {
  height: 20px;
  padding: 0 8px;
  background: #028740;
  color: var(--ion-color-primary-contrast);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-size: 12px;
  font-weight: $bold;
  text-transform: uppercase;
  flex-shrink: 0;
}

.file-type {
  background: rgba(0, 60, 235, 0.15);
}

.card-title-row {
  display: flex;
  align-items: center;

  .card-title {
    margin-right: 7px;
  }
}

.document-show-preview-pane {
  width: 100%;
}

.file-line-item {
  display: flex;
  flex-direction: column;
}

.recent-documents-wrapper {
  ion-router-link {
    text-decoration: underline;
    text-transform: capitalize;
  }
}

.document-viewer-wrapper {
  position: relative;
  padding-bottom: 20px;

  .document-page-wrapper {
    margin-bottom: 20px;
    border: 1px solid var(--ion-color-medium-tint);
    background-color: var(--ion-color-medium-tint);
    width: 100%;
    height: 400px;
    overflow: auto;
    padding-top: 100px;

    @include tablet {
      height: 600px;
      padding-top: 70px;
    }

    .document-page {
      min-width: auto !important;
      background-color: var(--ion-color-medium-tint) !important;

      & > * {
        margin: auto;
        right: 0;
      }
    }

    .document-page-bar {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      align-items: center;
      justify-content: space-between;
      position: fixed;
      top: 0;
      z-index: 10;
      width: 100%;
      background-color: var(--ion-color-dark, #222428);

      padding: 40px 10px 10px;
      height: 50px;
      width: calc(100% - 20px);

      @include tablet {
        padding: 10px;
      }

      .document-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 340px;

        .page-count,
        .page-scale {
          display: flex;
          align-items: center;
          color: var(--ion-color-light);
          font-weight: $bold;
          gap: 4px;
        }

        .page-count {
          ion-button {
            width: 48px;
          }
        }

        span {
          display: none;

          @include tablet {
            display: block;
            flex-shrink: 0;
          }
        }

        .document-download {
          ion-button {
            --padding-end: 11px;

            ion-icon {
              margin-right: 0px;
            }
          }
        }
      }

      .status-badge {
        margin-right: 15px;
        position: absolute;
        top: 10px;
        right: 0px;

        @include tablet {
          position: relative;
          top: unset;
        }
      }
    }
  }

  .document-thumbnails-wrapper {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
    padding: 0px 12px;
    flex-flow: wrap;

    .document-thumbnail {
      border: 1px solid var(--ion-color-light-shade);
      border-radius: 6px;
      overflow: hidden;

      canvas {
        @include mobile {
          width: 40px !important;
          height: 60px !important;
        }
      }
    }
  }
}

.files-list {
  ion-item {
    padding-top: 10px;
  }
}
