.time-tracking {
  text-align: center;
  color: var(--ion-text-color);

  dl {
    display: flex;
    justify-content: space-around;
    margin: 0;
    .column {
      flex: 1;
    }
    dt {
      font-size: 0.875rem;
      font-weight: $regular;
      margin: 0;
      min-height: 48px; // match ion-button min-height to vertically align headers with & without icon button
      display: flex;
      flex-direction: column;
      justify-content: center;
      ion-button {
        font-size: 0.875rem;
        font-weight: $regular;
        color: var(--ion-text-color);
        --padding-bottom: 0px;
        --padding-top: 0px;
        --padding-start: 0px;
        --padding-end: 0px;
        margin: 0;
      }
    }
    dd {
      font-size: 1.2rem;
      font-weight: $bold;
      line-height: 1.4rem;
      margin-bottom: 1.2rem;
    }
  }
}

.trip-card-wrapper {
  @include tablet {
    align-items: center;
    display: flex;
    padding: 24px;
    justify-content: space-between;
  }

  ion-card-title {
    display: flex;
    justify-content: space-between;
    font-size: 1.12rem;
    align-items: center;
  }

  ion-icon {
    position: absolute;
    right: 8px;
    top: 8px;
    font-size: 1.6rem;
    color: var(--ion-color-base);
    @include tablet {
      right: 12px;
      top: 12px;
      font-size: 1.8rem;
    }
  }

  .trip-summary {
    padding-bottom: 12px;
    @include tablet {
      flex: 1;
      padding: 5px;
    }

    dl {
      text-align: center;
      color: var(--ion-text-color);
      display: flex;
      justify-content: space-around;
      margin: 0;
      .column {
        flex: 1;
      }
      dt {
        font-size: 0.9rem;
        font-weight: $regular;
      }

      dd {
        font-size: 1rem;
        font-weight: $bold;
        margin-bottom: 0;

        @include tablet {
          font-size: 1.5rem;
        }
      }
    }
  }
}

.all-trips-wrapper {
  border-top: 2px solid var(--ion-color-light-shade);
  padding-top: 14px;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  // Indicate editing is not allowed for an ongoing trip
  ion-card.is-live {
    ion-card-title,
    dt,
    dd {
      opacity: 0.4;
    }
  }
}

.trip-note-wrapper {
  padding: 4px 12px 12px 12px;
  display: flex;
  flex-direction: column;

  ion-label {
    font-weight: $bold;
    color: var(--ion-text-color);
    margin-bottom: 4px;
  }

  ion-note {
    color: var(--ion-text-color);
  }

  @include tablet {
    padding: 0px 36px 24px 36px;
  }
}

.all-trips-summary-card {
  ion-card-title {
    text-align: center;
    text-transform: uppercase;
  }

  dl {
    color: var(--ion-text-color);
    margin: 0px 20px 10px;
    display: flex;
    justify-content: center;
    .column {
      flex: 1;
      text-align: center;
      &:first-of-type {
        border-right: 2px solid var(--ion-color-light-shade);
      }
    }
    dt {
      font-weight: $regular;
    }
    dd {
      font-size: 1.5rem;
      font-weight: $bold;
    }
    @include tablet {
      margin-left: 30%;
      margin-right: 30%;
    }
  }
}
