.table-expand-caret-cell {
  display: flex;
  align-items: center;

  span {
    margin-left: 6px;
  }

  ion-icon {
    cursor: pointer;
  }
}

td {
  ion-icon {
    margin-right: 4px;
  }
}
