.filter-form {
  margin: 16px 0;
  width: 100%;

  @include tablet {
    display: flex;
    gap: 16px;
    align-items: flex-start;
  }

  @include tablet-landscape {
    max-width: 500px;
  }

  .filter-items {
    display: flex;
    gap: 16px;
    width: 100%;

    @include tablet {
      width: calc(100% - -16px);
    }

    ion-item {
      width: 100%;
    }
  }

  ion-item {
    --background: transparent;
    --border: none;
    --padding-start: 0px;
    --inner-padding-end: 0px;
    --inner-border-width: 0px;
    --min-height: 44px;
    overflow: visible;

    @include tablet {
      min-width: 150px;
    }

    ion-label {
      position: absolute;
      top: -30px;
      left: 0px;
      font-size: 0.8rem !important;
    }
  }

  ion-select {
    min-height: 44px;

    @include tablet-landscape {
      min-width: 250px;
    }
  }

  ion-button {
    margin-top: 16px;
    text-transform: capitalize;

    @include tablet {
      min-height: 44px;
      flex: none;
      margin: 0px;
      width: 160px;
    }
  }
}
