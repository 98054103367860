.page-footer {
  display: none;

  @include tablet-landscape {
    background-color: var(--ion-color-primary);
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .inner {
    display: flex;
    max-width: 1024px;
    width: 100%;
    margin: 0px 9px;
    padding: 12px 36px;

    ul {
      display: flex;
      gap: 8px;
      list-style: none;
      align-items: center;
      padding-inline-start: 0px;
      margin: 0px;

      li {
        display: flex;
        gap: 4px;

        &:not(:first-child):before {
          content: '|';
          font-size: 14px;
        }
      }
    }

    ul + ul {
      margin-left: auto;
    }
  }

  li,
  p,
  a {
    color: var(--ion-color-light);
    margin: 0px;
  }

  a,
  p {
    font-size: 14px;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.pinned-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  background: var(--ion-color-light-shade);
  display: flex;
  padding: 10px 15px;
  gap: 6px;
  align-items: flex-start;

  p {
    margin: 0;
    font-size: 14px;
  }

  ion-icon {
    height: 21px;
    width: 23px;
  }

  @include tablet {
    align-items: center;
    bottom: 36px;
    left: 36px;
    right: 36px;
    height: 20px;
  }
}
