//Card
ion-grid.charts {
  padding: 0px;

  ion-row {
    gap: 0px 20px;

    ion-col {
      padding: 0px;

      @include tablet {
        width: calc(50% - 10px);
      }
    }
  }
}

ion-card.chart {
  width: 100%;

  ion-card-header {
    padding: 0;
  }

  ion-card-title {
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    margin: 20px auto;
  }

  .centered-content {
    margin-bottom: 40px;
  }

  .miles-chart-percent-label {
    font-size: 1.5rem;
    fill: var(--ion-color-base);
  }
}

//Toggle

.chart {
  ion-segment {
    margin: 0px 20px;
    width: calc(100% - 40px);
  }
}

//Legend

.recharts-default-legend {
  display: flex;
  justify-content: center;
}

.recharts-legend-item {
  &:last-of-type {
    margin-right: 0px !important;
  }

  @include tablet {
    width: 140px;
  }

  svg {
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
    border: 2px solid white;
    border-radius: 100%;
    margin-bottom: 10px;
  }

  .recharts-legend-item-text div {
    color: black !important;
    font-size: 0.875rem;
    min-width: 80px;
    padding: 10px;
  }
}

//Custom Tooltip

.custom-tooltip {
  background: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 8px;
  border-radius: 4px;

  .tooltip-row {
    display: flex;
    align-items: center;
    padding: 8px;

    p {
      margin-bottom: 0px;
      font-size: 0.8rem;
    }
  }
}

.tooltip-color-swatch {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 100%;
  margin-right: 10px;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  border: 2px solid white;
  border-radius: 100%;
}

//Footer

.chart-footer {
  display: inline-block;
  text-align: center;
  background: var(--ion-color-total-row);
  padding: 10px 20px;
  box-sizing: border-box;
  margin: 20px 20px 20px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: calc(100% - 40px);
  font-weight: bold;
  font-size: 0.875rem;
  color: var(--ion-color-base);
}
