.section-wrapper {
  margin: 25px 0;

  &:last-of-type {
    margin-bottom: 0px;
  }

  ion-select {
    border-radius: 4px;
    border: 1px solid #dddbda;
  }

  ion-accordion {
    border-radius: 4px;
    border: 1px solid #dddbda;

    ion-label {
      @include tablet {
        margin-left: 25px;
      }
    }

    ion-icon {
      @include tablet {
        position: absolute;
        left: -20px;
      }
    }
  }

  .accordion-back {
    padding: 10px;

    th,
    td {
      font-family: var(--ion-font-family);

      &:first-child {
        @include tablet-landscape {
          width: 32%;
          box-shadow: none;
        }
      }
    }

    th {
      font-weight: normal;
      color: #737373;
      padding-top: 0px;
    }

    td {
      padding: 8px 14px;
      line-height: 1.2;

      p {
        margin: 0 auto;
      }
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
}

.profit-plan-accordion-section-header-label {
  font-weight: var(--ion-font-weight-bold);
}

.accordion-back {
  height: auto;
  background: white;
}

.right-aligned-cell {
  text-align: right;
}

.profit-plan-wrapper {
  .filter-form {
    margin: 50px 0 25px;
  }

  h2 {
    font-size: 16px;
    margin: 0px;
  }
}
