$border-radius: 8px;

.custom-ion-text-input {
  ion-input {
    --padding-start: 4px;
  }
}

.input-label-placement-floating {
  margin: 10px 0;
}

ion-item.sc-ion-input-md-h:not(.item-label):not(.item-has-modern-input),
ion-item:not(.item-label):not(.item-has-modern-input) .sc-ion-input-md-h {
  --padding-start: 4px;
}

.input-fill-outline {
  margin: 14px 0;
}

ion-input {
  &.sc-ion-input-md-h {
    --border-width: 0px;
  }

  .input-wrapper,
  &.input-fill-outline.sc-ion-input-md-h .input-wrapper.sc-ion-input-md {
    border: 1px solid #b3b3b3;
  }
}

.ion-error-border {
  .input-outline-container {
    .input-outline-start.sc-ion-input-md {
      border-left: 1px solid #eb445a;
      border-top: 1px solid #eb445a;
      border-bottom: 1px solid #eb445a;
    }

    .input-outline-notch.sc-ion-input-md {
      border-top: none;
      border-bottom: 1px solid #eb445a;
    }

    .input-outline-end.sc-ion-input-md {
      border-inline-end: 1px solid #eb445a;
      border-top: 1px solid #eb445a;
      border-bottom: 1px solid #eb445a;
    }
  }
}

.native-input.sc-ion-input-ios {
  border-bottom: 1px solid #c8c7cc;
}

.error-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .error-text {
    padding: 6px 0;
    font-size: 0.75rem;
    color: var(--ion-color-danger);
  }

  .datetime-error-text {
    padding-left: 16px;
  }
}

// Select
ion-select {
  --placeholder-opacity: 1;

  &.ios {
    --padding-start: 8px;
    --padding-end: 8px;
    --border: 1px solid var(--ion-color-step-300);
    --border-radius: 4px;
  }

  &.md:after {
    border-top: 1px solid var(--ion-color-step-300, #b3b3b3);
    content: '';
    display: block;
    left: 10px;
    position: absolute;
    top: 0px;
    width: 10px;
  }

  &:hover {
    &:after {
      border-color: var(--ion-color-step-750, #404040);
    }
  }

  &.select-expanded {
    &:after {
      border-top: 2px solid var(--ion-color-step-750, #404040);
    }
  }

  &.ion-invalid {
    &:after {
      border-top: 1px solid var(--ion-color-danger, #eb445a);
    }

    &.select-expanded:after {
      border-top: 2px solid var(--ion-color-danger, #eb445a);
    }
  }

  .native-wrapper {
    --width: 100%;
  }

  .select-outline-notch {
    display: none;
  }
}

.has-focus.select-fill-outline.select-label-placement-floating.sc-ion-select-md-h
  .label-text-wrapper.sc-ion-select-md,
.has-value.select-fill-outline.select-label-placement-floating.sc-ion-select-md-h
  .label-text-wrapper.sc-ion-select-md,
.select-fill-outline.select-label-placement-stacked.sc-ion-select-md-h
  .label-text-wrapper.sc-ion-select-md,
.has-focus.input-fill-outline.input-label-placement-floating.sc-ion-input-md-h
  .label-text-wrapper.sc-ion-input-md,
.has-value.input-fill-outline.input-label-placement-floating.sc-ion-input-md-h
  .label-text-wrapper.sc-ion-input-md,
.input-fill-outline.input-label-placement-stacked.sc-ion-input-md-h
  .label-text-wrapper.sc-ion-input-md,
.label-floating.textarea-fill-outline.sc-ion-textarea-md-h
  .label-text-wrapper.sc-ion-textarea-md {
  transform: none;
  margin-top: -24px;
  margin-left: -14px;
  font-family: 'open sans';
  font-size: 0.75rem;
}

ion-checkbox {
  font-size: 0.75rem;

  &:focus {
    --border-color: var(--ion-color-base);
    --checkbox-background-checked: var(--ion-color-base);
  }
}

.select-fill-outline.select-label-placement-stacked .label-text-wrapper {
  transform: translateY(-90%) translateX(-30%) scale(0.75) !important;
}

.select-expanded {
  .select-outline-notch,
  .notch-spacer,
  .text-label {
    display: none !important;
    width: 0px !important;
  }
}

.error-message {
  font-size: 0.75rem;
  color: var(--ion-color-danger);
}

form {
  ion-input + ion-input label {
    margin-top: 45px;
  }
}

.ion-invalid .label-text {
  color: var(--ion-color-base);
}

.input-bottom {
  .error-text {
    margin-left: -14px;
  }
}

.input-outline-notch {
  display: none;
}

// Textarea

ion-textarea {
  --border-color: var(--ion-color-step-300, #b3b3b3);
  .textarea-wrapper-inner {
    height: 100%;
  }
}

//Radio Button
ion-radio-group.radio-toggle {
  display: flex;
  padding: 14px 0px;
  gap: 24px;

  ion-radio {
    font-weight: $bold;
  }
}

.date-time-button-wrapper {
  align-items: center;
  display: flex;
  gap: 14px;

  ion-datetime-button::part(native) {
    border-radius: 4px;
    font-weight: bold;
    padding: 12px;
    margin: 0px;

    @include tablet {
      width: calc(50% - 11px);
    }
  }

  ion-datetime-button {
    gap: 14px;
    display: flex;

    @include tablet {
      width: 100%;
    }

    button {
      width: 100px;
    }
  }
}

form {
  ion-button + ion-button {
    margin-top: 8px;
  }
}

form.bottom-buttons {
  @include mobile {
    min-height: calc(100vh - 150px);
    display: flex;
    flex-direction: column;
  }

  .button-group {
    margin-top: auto;

    ion-button,
    ion-router-link.button-style {
      &:not(:first-child) {
        margin-top: 8px;
      }
    }
  }
}

#custom-counter-text-area-wrapper {
  position: relative;
  height: 150px;
  // Target the IonTextarea within custom-counter-text-area-wrapper
  // and remove the border
  ion-textarea {
    --border-width: 0;
    --box-shadow: none;
    height: 150px;
  }

  .custom-counter-text-area-footer {
    position: relative;
    bottom: 24px;
    padding-right: 16px;
    padding-left: 16px;
    font-size: 0.75rem;
    display: flex;
    justify-content: space-between;
    margin: auto;

    .custom-counter-text-area-error {
      color: var(--ion-color-danger);
    }

    .custom-counter {
      right: 16px;
    }
  }
}
