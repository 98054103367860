.upload-flow-page {
  .flow-header {
    --background: var(--ion-color-dark);
    --color: var(--ion-color-primary-contrast);

    ion-title {
      font-size: 16px;
      font-weight: $bold;
    }

    @media (min-width: 500px) {
      ion-title {
        margin-inline-start: -48px;
      }
    }
    ion-icon {
      --color: var(--ion-color-primary-contrast);
    }
  }

  ion-content::part(background) {
    background: var(--ion-color-dark);
  }

  .flow-content-wrapper {
    height: 100%;
    width: 100%;

    color: var(--ion-color-primary-contrast);
  }
}

.document-preview-state {
  ion-item {
    --ion-color-base: transparent !important;
    --padding-start: 0px;
    --inner-padding-end: 0px;
    font-weight: $bold;
    font-size: 14px;
    margin-bottom: 8px;

    ion-icon[slot='start'] {
      margin-right: 8px;
    }
  }

  ion-list {
    background: transparent;

    ion-button {
      width: auto;
    }
  }

  ion-button {
    margin: 20px 0;
    width: 100%;
  }
}

.crop-wrapper {
  height: 100%;
}

@include tablet-landscape {
  .crop-wrapper {
    max-width: 1100px;
    margin: 0 auto;
    padding: 100px 0px;
    position: relative;
    height: calc(100% - 200px);
    display: flex;
    flex-direction: column;

    .App {
      position: absolute;
      left: 0px;
      width: 400px;
    }

    form {
      margin: auto;
      width: auto;
      max-width: 768px;
    }
  }
}
