input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.ytd-card-wrapper {
  ion-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  ion-card-header {
    width: 100%;
  }

  ion-card-title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    ion-button {
      color: var(--ion-text-color);
    }
  }

  ion-icon {
    width: 1.1rem;
    height: 1.1rem;
    margin-left: 2px;
  }

  ion-button {
    --padding-bottom: 0px;
    --padding-top: 0px;
    --padding-start: 0px;
    --padding-end: 0px;
    height: auto;
  }

  ion-card-subtitle {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 1.875rem;
    font-weight: bold;
    color: var(--ion-text-color);
  }

  ion-card-content {
    width: 100%;
    height: 40px;
    border-top: 1px solid var(--ion-color-light-shade);
    padding: 0;
    font-weight: bold;
    color: var(--ion-text-color);

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.per-diem-wrapper {
  max-width: 500px;
  margin: 0 auto;

  &.is-loading {
    .ytd-card-wrapper {
      opacity: 0.4;
    }
  }
  .calendar-block form {
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;

    & > * {
      width: 100%;
    }

    ion-datetime {
      max-width: 100%;
      border-radius: 10px;
      border: 1px solid #dddbda;
    }

    h2 {
      font-size: 1rem;
      margin-top: 0px;
    }
  }
}

.date-selection {
  p {
    margin-top: 0px;
  }
  .loading-container {
    min-height: 352px; // ideally about the same height as IonDateTime
    display: flex;
    justify-content: center;
    ion-spinner {
      margin-top: 100px;
    }
  }
}

.calendar-key {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  gap: 10px;

  @include tablet {
    gap: 20px;
  }

  div {
    display: flex;
    gap: 8px;
    align-items: center;

    span {
      font-size: 14px;
    }
  }

  .key-circle {
    height: 16px;
    width: 16px;
    border-radius: 100%;
    flex: none;

    &.gold {
      background-color: var(--ion-color-secondary);
    }

    &.light-gold {
      background-color: rgba(var(--ion-color-secondary-rgb), 0.4);
    }

    &.grey {
      background-color: #838383;
      border: 1px solid #dddbda;
    }
  }
}

ion-datetime {
  &.ion-color-primary {
    --ion-color-base: #838383 !important;
  }
}
