$primary-font: 'OpenSans-Regular', sans-serif;
$primary-font-medium: 'OpenSans-Bold', sans-serif;
$primary-font-semibold: 'Oswald-Bold', sans-serif;

:root {
  --ion-font-family: 'Open Sans', sans-serif;
  --ion-font-weight-bold: 700;
}

body {
  font-family: var(--ion-font-family);
}

$regular: 500;
$bold: 700;

p {
  font-size: 0.875rem;
  line-height: 19px;

  @include tablet {
    font-size: 1rem;
    line-height: 22px;
  }
}

a {
  color: var(--ion-color-base);

  &:hover {
    color: var(--ion-color-primary);
  }
}

.font-bold {
  font-weight: $bold;
}
