.resources-wrapper {
  ion-list {
    padding: 0px;
  }
}

ion-item.item-link {
  margin: 8px 0;
  font-weight: $bold;
  border-radius: $border-radius;

  ion-label,
  .item.sc-ion-label-md-h,
  .sc-ion-label-md-h {
    text-decoration: underline;
    white-space: normal;
    padding-left: 16px;
  }

  &:hover {
    ion-label,
    .item.sc-ion-label-md-h,
    .sc-ion-label-md-h {
      opacity: 0.8;
    }
  }
}

.drive-embed {
  --background: #fff;
  --border-radius: 8px;
  padding-top: 12px;
}
