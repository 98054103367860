// Imports
@import '../../node_modules/cropperjs/dist/cropper.css';
@import '../../node_modules/react-pdf/dist/esm/Page/AnnotationLayer.css';
@import '../../node_modules/react-pdf/dist/esm/Page/TextLayer.css';

// Settings
$action-button-height: 70px;
$action-button-height-tablet: 50px;

// Global
* {
  box-sizing: content-box;
}

// Base Styles
@import 'base/media-queries';
@import 'base/typography';
@import 'base/colors';
@import 'base/forms';
@import 'base/focus-states';

// Components
@import 'components/buttons';
@import 'components/layout';
@import 'components/select';
@import 'components/modal';
@import 'components/navigation-sidebar';
@import 'components/navigation-menu';
@import 'components/filter-form';
@import 'components/icons';
@import 'components/footers';
@import 'components/segment';
@import 'components/cards';
@import 'components/header';
@import 'components/charts';
@import 'components/table';
@import 'components/pagination';
@import 'components/flinks';
@import 'components/filtered-content-table';

// Page Styles
@import 'pages/home';
@import 'pages/sign-in';
@import 'pages/resources';
@import 'pages/camera-flow';
@import 'pages/document-show';
@import 'pages/document-manager';
@import 'pages/splash';
@import 'pages/per-diem';
@import 'pages/profit-plan';
@import 'pages/tutorials';
@import 'pages/financial-performance';
@import 'pages/time-tracking';
@import 'pages/add-trip';
@import 'pages/hub-message-show';
