.layout-content-wrapper {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;

  .layout-content {
    max-width: 1024px;
    margin: auto;
    min-height: calc(100% - 40px);
    padding: 20px;
    position: relative;
    overflow: auto;

    @include tablet {
      min-height: calc(100% - 72px);
      padding: 36px;
    }

    @include tablet-landscape {
      min-height: calc(100% - 118px);
    }
  }
}

.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 14px;
  text-align: center;

  ion-icon {
    font-size: 4rem;
  }

  h2 {
    font-size: 1rem;
    font-weight: $bold;
  }

  ion-icon + h2 {
    margin-top: 0px;
  }

  &.full-page {
    min-height: calc(100vh - 150px);

    @include tablet {
      min-height: calc(100vh - 200px);
    }

    &.header-offset {
      @include tablet {
        min-height: calc(100vh - 280px);
      }
    }

    ion-router-link {
      max-width: 325px;
    }
  }

  &.ion-padding {
    box-sizing: border-box;
  }
}

.inline-block {
  display: inline-block;
}

.ion-padding-md {
  @include tablet {
    --padding-top: 32px;
    --padding-bottom: 32px;
    --padding-start: 32px;
    --padding-end: 32px;
  }
}

// Header
.layout-content {
  header {
    margin-bottom: 24px;
    width: 100%;

    h2 {
      margin: 0px;
      font-size: 16px;
      font-weight: $bold;
    }

    p {
      margin: 8px 0px 0px;
      font-size: 16px;
    }
  }
}

ion-title.ios {
  padding-inline: 0;
}
