ion-segment {
  background: var(--ion-color-light);
  border: 1px solid #dddbda;
  min-height: 40px;
  border-radius: 10px;

  ion-segment-button {
    font-size: 16px;

    &.segment-button-checked {
      font-weight: $bold;
      margin: 4px 2px;
    }
  }
}

ion-segment-button::part(indicator-background) {
  background: #003ceb;
  height: 32px;
}

.segment-button-checked.ios::part(native) {
  color: #fff;
}
