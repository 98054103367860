.flinks-container {
  position: absolute;
  inset: 20px;

  iframe.flinks-connect {
    width: 100%;
    height: 0;

    &.loaded {
      height: 100%;
    }
  }
}
