.atbs-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 33px;
  width: 33px;
  border-radius: 50%;
  background: var(--color-hub-message);
  font-weight: $bold;
  color: var(--ion-text-color);
  font-size: 0.575rem;
}

.doc-img {
  height: 33px;
  width: 33px;
  margin-right: 12px;
}

.user-img {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 33px;
  width: 33px;
}

ion-card:not(.default-card-styles) {
  .hub-message-ion-card-header {
    padding-top: 20px;
    padding-left: 28px;
    padding-right: 28px;
  }

  .hub-message-document-header {
    padding-bottom: 2px;
  }

  .hub-message-show-content {
    padding-left: 28px;
    padding-right: 28px;
    padding-bottom: 20px;
  }
}

.hub-message-show-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22px;
}

.hub-message-show-title {
  font-size: 1.25rem;
  font-weight: $bold;
  margin-top: 0px;
}

.hub-message-show-action-needed-badge {
  margin-top: 0px;
  margin-bottom: 14px;
  margin-left: 0px;
  margin-right: 0px;
}

.hub-message-button {
  margin-top: 24px;
  max-height: 50px;
}

.reply-buttons-wrapper {
  display: flex;
  justify-content: end;
  padding-top: 2px;
}

.hub-message-non-archive-button {
  bottom: 85px;

  @include tablet-landscape {
    bottom: 10px;
  }
}

.hub-message-reply-button {
  width: 75px;
  min-height: 40px;
  margin-top: 0px;
  font-size: 0.875rem;

  @include mobile {
    width: 100%;
  }
}
.hub-message-cancel-reply-button {
  margin-right: 4px;
  width: 50%;

  @include mobile {
    margin-right: 8px;
  }
}

.hub-message-send-reply-button {
  margin-left: 4px;
  margin-right: 0px;
  width: 50%;

  @include mobile {
    margin-left: 8px;
  }
}

.hub-message-document-title {
  color: black;
  font-size: 1rem;
  font-weight: $bold;
  display: flex;
  align-items: center;
}

.hub-message-document-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.archival-confirmation-bold-text {
  font-weight: $bold;
  text-align: center;
  margin-bottom: 12px;
  font-size: 1rem;
}

.archival-confirmation-main-text {
  text-align: center;
  margin-bottom: 12px;
}

.archival-confirmation-row {
  margin-bottom: 12px;
}

.margin-top-20 {
  margin-top: 20px !important;
}
