#filtered-content-table-card {
  margin: 20px 0px 0px 0px;
}

.table-content-line-item-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0px;

  &.hub-message-line-item-wrapper {
    display: block;
  }

  .table-content-text-block {
    padding-right: 14px;
    width: calc(100% - 148px);

    &.hub-message-line-item-text-block {
      width: 100%;
    }
  }

  .table-content-line-item-title {
    font-weight: 700;
    font-size: 1rem;
    line-height: 22px;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .table-content-line-item-subtitle {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 16px;
    color: #666666;
  }

  // HubMessage Specific:
  .archived-line-item {
    font-weight: 400 !important;
  }
  .hub-message-line-item-subtitle {
    margin-bottom: 10px;
  }

  .hub-message-line-item-text-wrapper {
    display: inline-grid;
  }
}

.no-data-content {
  min-height: 337px;

  span {
    font-weight: var(--ion-font-weight-bold);
    color: var(--ion-color-base);
    font-size: 1rem;
  }

  &.dark {
    background: var(--ion-flow-background);

    p {
      color: var(--ion-color-light);
    }
  }
}

// HubMessage Specific:
.message-action-needed-badge {
  height: 20px;
  width: 108px;
  background: var(--color-hub-message);
  color: var(--ion-text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-size: 0.75rem;
  font-weight: $bold;
  flex-shrink: 0;
  margin-top: 6px;
}
