// Header
.header-md {
  box-shadow: none;
}

.main-header {
  background-color: var(--ion-color-dark);

  .desktop-menu-bar {
    background-color: var(--ion-color-dark);
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 80px;
    width: 100%;
  }

  ion-toolbar {
    max-width: 1042px;
    margin: 0 auto;
    padding: var(--ion-safe-area-top) 15px 0;
    width: calc(100% - 16px);
    padding-left: 8px;
    padding-right: 8px;
    --background: var(--ion-color-dark);
    --color: var(--ion-color-light);
    --ion-toolbar-color: var(--ion-color-light);

    &.md {
      width: calc(100% - 8px);
      padding-left: 4px;
      padding-right: 4px;
    }

    @include tablet-landscape {
      max-width: 1060px;
    }
  }

  ion-title {
    font-weight: $bold;
    font-size: 1rem;

    @include tablet {
      font-size: 1.5rem;

      &.md {
        min-height: 80px;
      }
    }
  }

  ion-buttons.buttons-last-slot {
    ion-button {
      width: 32px;
    }
  }

  @include tablet {
    &:not(.force-dark-header) {
      background-color: var(--ion-background-color);

      ion-toolbar {
        --background: var(--ion-background-color);
        --color: var(--ion-color-dark);
        --ion-toolbar-color: var(--ion-color-dark);
      }
    }
  }
}
