.splash-wrapper {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  box-sizing: border-box;

  @include tablet {
    justify-content: center;
  }

  ion-icon {
    color: black;
  }

  h2 {
    margin-top: 24px;
    flex: none;
    color: var(--ion-color-primary-contrast);
    font-size: 16px;
    font-weight: $bold;

    &.secondary {
      color: var(--ion-color-secondary);
    }
  }

  ion-text {
    margin-top: 4px;
    max-width: 500px;
    font-size: 16px;
  }

  .flex-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    @include tablet {
      height: auto;
      margin-bottom: 32px;
    }
  }

  ion-button {
    flex: none;
    max-width: 310px;
    text-transform: unset;
    width: 100%;
  }

  .links {
    text-align: center;
  }
}

.splash-wrapper-with-documents {
  max-width: 100%;

  @include tablet-landscape {
    max-width: 1100px; // matches Camera Flow on desktop
    margin: 0 auto;
  }
}
