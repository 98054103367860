@import 'https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css';

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  gap: 4px;

  .page-item {
    .page-link {
      border: none;
      border-radius: 0px;
      color: var(--ion-color-base);
      font-weight: var(--ion-font-weight-bold);
      min-width: 12px;

      &:focus {
        box-shadow: none;
      }
    }

    &.active .page-link {
      background: var(--ion-color-dark);
      border-color: var(--ion-color-dark);
      color: var(--ion-color-primary-contrast);
    }

    &.disabled .page-link {
      opacity: 0.4;
    }
  }
}
