ion-select {
  --background: #fff;
  --width: 100%;
}

#select-label > div.native-wrapper {
  width: 100%;
}

ion-select::part(icon) {
  justify-content: flex-end;
}
