.icon-circle-bg {
  height: 62px;
  width: 62px;
  background-color: var(--ion-color-secondary);
  margin: 0 auto 12px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;

  &.success {
    background-color: var(--ion-color-success);
  }

  &.dark {
    background-color: var(--ion-color-base);
  }

  &.no-objects {
    ion-icon {
      color: var(--ion-color-base);
    }
  }
}

.light {
  .icon-circle-bg {
    &.no-objects {
      background-color: var(--ion-color-primary-off-tint);

      ion-icon {
        color: var(--ion-color-base);
      }
    }
  }
}
