.document-manager-wrapper {
  ion-reorder {
    display: block;
  }

  .document-list-item {
    display: flex;
    flex-direction: column;
    gap: 6px;

    ion-note {
      color: var(--ion-color-light-shade);
    }
  }
}
