:root {
  --ion-background-color: #f5f5f5;
  --ion-background-color-rgb: 245, 245, 245;
  --ion-color-base: #000;
  --ion-color-base-rgb: 0, 0, 0;
  --ion-color-base-light: #666;
  --ion-color-primary: #333;
  --ion-color-primary-rgb: 51, 51, 51;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #2d2d2d;
  --ion-color-primary-tint: #474747;
  --ion-color-primary-off-tint: #f1f1f1;
  --ion-color-secondary: #e7b12f;
  --ion-color-secondary-rgb: 231, 177, 47;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #a17a1c;
  --ion-color-secondary-tint: #be9736;
  --ion-color-tertiary: #003ceb;
  --ion-color-tertiary-rgb: 62, 177, 203;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #379cb3;
  --ion-color-tertiary-tint: #51b9d0;
  --ion-color-success: #7bbd51;
  --ion-color-success-rgb: 52, 225, 133;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #2ec675;
  --ion-color-success-tint: #48e491;
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  --ion-color-danger: #d40511;
  --ion-color-danger-rgb: 251, 54, 54;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #a2000a;
  --ion-color-danger-tint: #fb4a4a;
  --ion-color-medium: #f1f1f1;
  --ion-color-medium-rgb: 241, 241, 241;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  --ion-flow-background: #313131;
  --ion-card-background: #fff;
  --ion-item-background: #fff;
  --ion-text-color: #000;
  --ion-color-total-row: #f0f0f0;
  --ion-color-master-row: #d9d9d9;
  --ion-color-dark: #333333;
  --ion-color-green: #028740;
  --ion-color-green-shade: #026e34;
  --ion-color-alert: #e64646;
  --color-hub-message: #ffcc00;
}

.color-disclaimer,
.color-disclaimer a {
  color: var(--ion-color-base-light);
}
