.atbs-logo {
  width: 100px;
  margin-left: 10px;
  margin-bottom: 0px;
}

ion-menu,
ion-menu ion-content {
  --background: var(--ion-card-background);
}

ion-menu {
  --width: 20rem;
  --max-width: calc(100% - 60px);

  .nav-menu-header {
    box-sizing: border-box;
    padding-bottom: 0px;

    ion-menu-toggle {
      display: flex;
      align-items: center;
    }

    .button-clear {
      --padding-start: 0;
      --padding-end: 0;
      --background-hover: transparent;
    }
  }

  .main-links {
    flex: auto;

    @media (max-height: 820px) {
      flex: none;
    }

    ion-list {
      padding-bottom: 0px;
    }
  }

  .ion-content-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: var(--ion-safe-area-top);
  }

  .menu-navigation {
    ion-icon {
      font-size: 22px;
      margin: 0 16px 0 12px;
      color: var(--ion-color-base);
    }

    ion-accordion {
      ion-item {
        --inner-padding-end: 0;
      }
    }

    ion-item {
      font-weight: $bold;

      &.accordion-label-button {
        --border-width: 0;
      }
    }

    .accordion-contents {
      padding: 0 0 0 50px;

      ion-item {
        font-weight: normal;
      }
    }
  }

  .secondary-menu-nav-group {
    ion-list {
      padding: 0;
    }

    .social-media-links {
      display: flex;
      padding-left: 18px;
      margin-top: 12px;

      ion-item {
        --inner-padding-start: 8px;
        --inner-padding-end: 8px;
        --padding-start: 0;
        --padding-end: 0;
        --inner-min-width: 1rem;

        ion-icon {
          color: var(--ion-color-base);
        }
      }
    }

    .menu-disclaimer {
      padding: 0 28px;
      font-size: 0.875rem;

      p {
        font-size: inherit;
        line-height: normal;
      }

      #version-number {
        font-size: 0.9em;
      }
    }
  }
}
