.add-trip-wrapper {
  form {
    max-width: 500px;
    margin: 0 auto;
    padding: 0 6px;
    > div {
      display: flex;
      flex-direction: column;
    }
    .error-wrapper {
      margin-bottom: 10px;

      ion-label {
        margin-left: 5px;
      }
    }

    .delete-button-wrapper {
      border-top: 1px solid var(--ion-color-light-shade);
      padding-top: 6px;
    }

    ion-button.button-clear {
      display: block;
      margin: 0 auto;
    }

    ion-label {
      color: black;
      font-size: 12px;
      margin-bottom: 4px;
    }

    ion-input,
    ion-textarea {
      margin: 24px 0px;
      color: var(--ion-color-base);
      font-size: 1rem;

      .label-text {
        color: var(--ion-color-base);
        font-size: 12px;
        margin-bottom: 4px;
        margin-left: -3px;
      }
    }
    ion-textarea {
      height: 150px;
      margin-bottom: 40px;
      .textarea-outline-notch {
        width: 0px;
      }
    }
  }
}
