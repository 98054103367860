$nav-pane-width: 75px;

ion-split-pane {
  --side-width: #{$nav-pane-width};
  --side-max-width: #{$nav-pane-width};
  --side-min-width: #{$nav-pane-width};

  .sidebar-navigation-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid var(--ion-color-base);
    width: $nav-pane-width;
    padding: 4px 0;

    ion-icon {
      font-size: 22px;
      color: var(--ion-color-base);
    }

    .main-side-nav-group {
      margin-top: 33px;

      ion-menu-toggle {
        cursor: pointer;
      }
    }
  }
}

.split-pane-side {
  ion-menu-button:hover {
    --background-hover: transparent;
  }

  ion-list {
    background: #fff;
    border: none;
  }

  ion-item {
    --background: #fff;
  }

  ion-item:hover {
    --background: var(--ion-color-light-tint);
  }
}

ion-menu {
  ion-header {
    background: var(--ion-color-light);
  }
}
