$modal-padding: 32px;
$modal-border-radius: 16px;

ion-modal {
  --width: 100%;

  ion-toolbar {
    --background: white;
  }

  ion-title {
    font-size: 16px;
    font-weight: $bold;
  }

  // Since modal height is always specified instead of 100%, unset the default extra padding added in the header toolbar
  ion-header ion-toolbar:first-of-type {
    padding-top: 0px;
  }
}

.sheet-modal {
  min-width: 325px;
  max-width: 800px;
  width: 80%;
}

.modal-justify {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ion-nav-link {
    width: 100%;
  }

  ion-button {
    width: 100%;
    text-transform: capitalize;
  }
}

.modal-align {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ion-button {
    width: 100%;
    text-transform: capitalize;
  }
}

.modal-flexbox {
  display: flex;
  justify-content: center;
  align-items: center;
}

ion-modal.custom-modal {
  --height: 375px;
  --border-radius: #{$modal-border-radius};
  padding: $modal-padding;

  @include tablet {
    --width: 350px;
  }

  ion-toolbar {
    padding-left: 0px;
  }

  ion-content {
    --background: white;
  }

  ion-grid {
    background: white;
    padding: 10px;
    margin: 0;
  }

  ion-textarea {
    height: 150px;
    width: 95%;

    .textarea-outline-notch {
      width: 0px;
    }
  }

  .button-inner {
    color: var(--ion-color-base);
  }
  ion-icon {
    color: var(--ion-color-base);
  }

  ion-button {
    width: 100%;
    text-transform: none;
    margin-left: 10px;
    margin-right: 10px;
  }

  .modal-row-with-larger-margin {
    margin-bottom: 32px;
  }
}

ion-modal.fab-modal {
  --border-radius: #{$modal-border-radius};
  --ion-background-color: #ffffff;

  @include tablet {
    --width: 580px;
    --height: 350px;
    margin-top: -30%;

    &::part(content) {
      position: relative;
    }
  }

  .sheet-modal {
    text-align: center;
    align-items: unset;

    @include tablet {
      width: 100%;
    }

    @include tablet {
      text-align: left;
    }

    h2 {
      font-size: 16px;
      font-weight: $bold;
      margin-top: 0px;
    }

    p,
    a {
      font-size: 14px;
      margin: 0px 0px 16px;

      @include tablet {
        font-size: 16px;
      }
    }

    a {
      font-weight: $bold;
    }
  }
}

ion-modal#info-modal {
  --height: 500px;
  --border-radius: #{$modal-border-radius};
  --background: white;
  padding: $modal-padding;

  &.ytd-deductions-info-modal {
    --height: 580px;
  }
  &.per-diem-info-modal,
  &.savings-info-modal {
    --height: 380px;
  }
  &.mileage-tracker-info-modal {
    --height: 330px;
  }

  @include tablet {
    --width: 45%;
  }

  ion-toolbar {
    padding-left: 0px;
  }

  ion-content {
    --background: white;
    --padding-start: 20px;
    --padding-end: 20px;
    --padding-top: 20px;
    --padding-bottom: 20px;

    p,
    li,
    a {
      font-size: 0.9rem;
      line-height: 1.5;
    }

    *:last-child {
      margin-bottom: 0px;
    }
  }

  ion-grid {
    background: white;
    padding: 10px;
  }

  ion-textarea {
    height: 150px;
    width: 95%;

    .textarea-outline-notch {
      width: 0px;
    }
  }

  .button-inner {
    color: var(--ion-color-base);
  }
  ion-icon {
    color: var(--ion-color-base);
  }

  ion-button {
    width: 100%;
    text-transform: none;
  }

  ion-row:not(:first-of-type) {
    margin-top: 32px;
  }

  ion-content + ion-row {
    padding: 20px;

    ion-button {
      margin: 0px;
    }

    ion-button + ion-button {
      margin-top: 8px;
    }
  }
}

ion-modal#save-error-modal {
  --height: 580px;
  padding: $modal-padding;
  --border-radius: #{$modal-border-radius};

  @include tablet {
    --width: 45%;
  }

  ion-content {
    --background: white;
  }
  ion-toolbar {
    padding-left: 0;
  }

  .save-error-modal-inner {
    padding: 20px 16px;
    dl {
      border: 1px solid var(--ion-color-light-shade);
      border-radius: 8px;
      padding: 10px 12px;
      dd {
        font-size: 0.9rem;
      }
    }
    div.centered {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding-top: 10px;
    }
  }
}

ion-alert#warning-alert {
  .alert-wrapper {
    border-radius: 13px;
  }
  h2 {
    color: var(--ion-color-base);
    font-weight: $bold;
    text-align: center;
    font-size: 1rem;
    text-wrap: balance;
  }
  .alert-message {
    text-align: center;
    color: var(--ion-color-base);
    font-size: 0.9rem;
  }
  .alert-button-group {
    padding: 0 8px 4px 8px;
    &.sc-ion-alert-ios {
      width: unset;
    }
    &.sc-ion-alert-md {
      display: flex;
      flex-direction: column;
      .alert-button-inner.sc-ion-alert-md {
        justify-content: center;
      }
    }
    button {
      border-radius: 8px;
      border: 1px solid var(--ion-color-base-light);
      margin: 0 5px 10px 5px;
      text-transform: unset;

      &.alert-button-cancel {
        background-color: var(--ion-color-secondary);
        border: 1px solid var(--ion-color-secondary);
      }

      span {
        font-weight: $bold;
        color: var(--ion-color-base);
        font-size: 1rem;
      }
    }
  }
}
