// Layout
.sign-in-layout {
  --background: #fff;
  --padding-bottom: 50px;
}

// Header
.sign-in-header-wrapper {
  max-width: 700px;
}

.sign-in-header {
  // Logo
  ion-img {
    margin-bottom: 50px;
    width: 170px;

    @include tablet {
      margin-bottom: 100px;
      width: 215px;
    }
  }

  .sign-in-header-inner {
    display: block;
    margin: 0 auto;
    max-width: 700px;

    h1 {
      font-size: 1rem;
      text-transform: uppercase;
      font-family: oswald;
      font-weight: $bold;

      &:after {
        background: #000;
        content: '';
        display: block;
        height: 4px;
        margin-top: 8px;
      }
    }

    p:first-of-type {
      margin: 8px 0px 0px;

      @include tablet {
        margin: 36px 0px 0px;
      }
    }

    p:first-of-type + p {
      margin-top: 8px;
    }

    *:last-child {
      margin-bottom: 0px;
    }
  }

  .sign-in-header-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p:first-of-type {
      font-weight: $bold;
      font-size: 1rem;
    }
  }
}

// Form
.sign-in-form {
  padding-bottom: 0px;
  display: block;
  margin: 0 auto;

  @include tablet {
    max-width: 330px;
  }

  ion-button {
    margin-top: 24px;
    font-size: 1.1rem;
    text-transform: capitalize;
  }
}

// Link
.forgot-password-link {
  ion-router-link {
    margin-top: 24px;
    display: inline-block;
    width: auto;
    text-decoration: underline;
    font-weight: $bold;
    text-transform: capitalize;
  }
}

// Footer
.sign-in-footer {
  p,
  p a {
    color: #666;
  }

  p:first-of-type {
    color: #000;
    font-weight: $bold;
    margin-bottom: 0px;
  }

  p:first-of-type + p {
    margin-top: 0px;
  }

  *:last-child {
    margin-bottom: 0px;
  }
}

.password-requirement-list {
  text-align: start;
}
