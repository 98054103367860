//Focus States

@mixin focus-style {
  background: none;
  display: block;
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: 2px solid #0c57d0;
  height: 100%;
  width: 100%;
  z-index: 2;
  opacity: 1;
  pointer-events: none;
}

.ion-focused,
ion-select.ion-focused,
ion-item.ion-focused,
ion-textarea,
ion-input,
ion-button.ion-focused ion-checkbox {
  position: relative;
}

.ion-focused:after,
ion-select.ion-focused:after,
form ion-item.ion-focused:after,
ion-textarea.has-focus:after,
ion-input.has-focus:after {
  @include focus-style;
  border-radius: 4px;
}

ion-input.has-focus:after {
  height: 58px;
}

ion-input.ion-invalid:after {
  display: none;
}

ion-item.ion-focused:after,
ion-button.ion-focused:after {
  border-radius: 8px;
  background-color: transparent;
  @include focus-style;
}

.input-fill-outline.has-focus.sc-ion-input-md-h {
  --border-color: white;
}

.menu-navigation ion-menu-toggle ion-item.ion-focused:after,
.page-link:focus:after {
  @include focus-style;
}

ion-card.ion-activatable {
  position: relative;

  &:focus:after {
    border-radius: 10px;
    @include focus-style;
  }
}

ion-toolbar ion-button.ion-focused:after,
ion-back-button.back-button-has-icon-only.ion-focused:after {
  border-radius: 20em;
}

ion-checkbox:focus {
  &:after {
    border-radius: 2px;
    @include focus-style;
    width: 18px;
  }
}
