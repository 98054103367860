.video-responsive-wrapper {
  @include tablet {
    width: 50%;
  }

  .video-responsive {
    margin: 0px 0px 20px;
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
  }

  .video-responsive iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.tutorials-wrapper {
  padding: 8px;

  .tutorial-item {
    --padding-start: 0px;

    &:last-child {
      .video-responsive {
        margin-bottom: 0px;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid #dddbda;
      margin-bottom: 20px;
    }

    @include tablet {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
    }

    h2 {
      font-weight: bold;
      color: var(--ion-color-base);
      margin-bottom: 8px;
      width: 100%;

      @include tablet {
        margin: 0px 0px 0px 16px;
        width: 50%;
      }
    }
  }
}
