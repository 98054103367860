:root {
  --ion-padding: 32px;
}

@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin tablet-landscape {
  @media (min-width: 1200px) {
    @content;
  }
}
